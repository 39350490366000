<template>
    <div class="article-content">
        <el-scrollbar class="article-wrap">
            <div class="top">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                    <el-breadcrumb-item>网络推广</el-breadcrumb-item>
                    <el-breadcrumb-item>软文推广</el-breadcrumb-item>
                    <el-breadcrumb-item>新的创作</el-breadcrumb-item>
                </el-breadcrumb>
                <!--                <el-button type="primary">下载素材</el-button>-->
            </div>
            <div class="main-content">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="标题">
                        <el-input class="s800-input" v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="作者">
                        <el-input class="s500-input" v-model="form.author"></el-input>
                    </el-form-item>
                    <el-form-item label="正文" prop="analysis" class="subject-quill">
                        <vue-tinymce :setting="setting" v-model="form.content"></vue-tinymce>
                    </el-form-item>
                    <el-form-item label="封面" prop="analysis" class="subject-quill">
                        <el-upload
                                class="cover-uploader"
                                action="stuOp/liveUploadImg"
                                name="img"
                                :headers="headerParam"
                                :show-file-list="false"
                                :on-success="handleCoverSuccess"
                                :before-upload="beforeCoverUpload">
                            <div class="inner-box">
                                <div class="inner-content">
                                    <i class="el-icon-plus"></i>
                                    <span class="inner-text">选择封面</span>
                                </div>
                            </div>
                            <div class="cover" v-if="form.cover">
                                <img :src="form.cover">
                                <div class="cover-bottom">重新上传</div>
                            </div>
                        </el-upload>
                        <span class="item-txt-tip">建议尺寸375px*160px</span>
                    </el-form-item>
                    <el-form-item label="平台">
                        <el-select v-model="form.platform" placeholder="请选择">
                            <el-option label="微博" value="1"></el-option>
                            <el-option label="公众号" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="page-button">
                    <el-button @click="backBtn">返回</el-button>
                    <el-button type="primary" style="margin-left: 20px;" @click="onSubmit('form')">发布</el-button>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import Upload from '@/utils/slice_upload';
    import {stuOpSaveArticle, stuOpArticleDetail} from '@/utils/apis'

    export default {
        name: "createArticle",
        data() {
            return {
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                editId: Number(this.$route.query.edit_id) || null,
                platform: this.$route.meta.type,
                form: {
                    title: '',
                    author: '',
                    content: '',
                    cover: '',
                    platform: '',
                },
                cover: [],
                setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300,
                    //上传图片
                    images_upload_handler: function (blobInfo, success, failure, progress) {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', 'operationProblem/uploadTextImg');//后端给的上传图片接口
                        xhr.setRequestHeader("Authorization", localStorage.getItem('studentToken'));

                        xhr.upload.onprogress = function (e) {
                            progress(e.loaded / e.total * 100);
                        }

                        xhr.onload = function () {
                            var json;
                            if (xhr.status == 403) {
                                failure('HTTP Error: ' + xhr.status, {remove: true});
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300) {
                                failure('HTTP Error: ' + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            success(json.location);
                        };

                        xhr.onerror = function () {
                            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                        }

                        formData = new FormData();
                        formData.append('img', blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    }
                },
                headerParam: {
                    Authorization: localStorage.getItem('studentToken') || ''
                },
            }
        },
        created() {
            if (this.editId !== null) {
                this.getEditList();
            }
        },
        methods: {
            getEditList() {
                let param = {
                    id: this.editId
                }
                stuOpArticleDetail(param).then(res => {
                    this.form.title = res.data.title;
                    this.form.content = res.data.content;
                    this.form.author = res.data.author;
                    this.form.cover = res.data.cover;
                    this.form.platform = res.data.platform;
                }).catch(err => {
                    console.error(err)
                })
            },
            backBtn() {
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/softPromotion',
                        query: {
                            id: this.opId,
                            course_id: this.courseId
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/examing/softPromotion',
                    })
                }
            },
            handleCoverSuccess(res, file) {
                if (res.code == 200) {
                    this.form.cover = res.data; //无域名
                    this.$message.success('上传成功')
                } else {
                    this.$message.error('上传失败，请稍后再试~')
                }
            },
            beforeCoverUpload(file) {
                const isSize = new Promise((resolve, reject) => {
                    const width = 375;
                    const height = 160;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                        return file;
                    }, () => {
                        this.$message.warning('图片尺寸限制为375px x 160px');
                        return Promise.reject();
                    },
                );
                return isSize;
            },
            onSubmit(formName) {
                let isErrNum = 0;
                if (this.form.title == '') {
                    isErrNum = 1
                }
                if (this.form.author == '') {
                    isErrNum = 1
                }
                if (this.form.content == '') {
                    isErrNum = 1
                }
                if (this.form.cover == '') {
                    isErrNum = 1
                }
                if (this.form.platform == '') {
                    isErrNum = 1
                }
                if (isErrNum === 1) {
                    this.$message.warning('每项为必填！')
                    return false
                } else {
                    let param = this.form;
                    if (this.platform == 0) {
                        param.op_id = this.opId;
                        param.course_id = this.courseId
                    }
                    ;
                    stuOpSaveArticle(param).then(res => {
                        this.$message.success(res.msg);
                        this.backBtn();
                    }).catch(err => {
                        console.error(err)
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .article-content {
        display: flex;
        flex-direction: column;
        height: calc(100% - 50px);
        padding: 0 0 50px;

        .article-wrap {
            height: 100%;
            display: flex;
            flex-direction: column;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 0 20px;
                }
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F1F5FF;
            height: 40px;
            padding-bottom: 10px;

            .genera-breadcrumb {
                display: flex;
                align-items: center;

                &:before {
                    content: unset;
                }
            }
        }

        .main-content {
            margin-top: 20px;
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .s500-input {
                width: 500px;
            }

            .s800-input {
                width: 800px;
            }
        }

        .page-button {
            text-align: center;
        }

        .cover-uploader {
            width: 800px;

            ::v-deep.el-upload {
                display: flex;
                align-items: center;

                .inner-box {
                    width: 375px;
                    height: 160px;
                    background: #E7F6EF;
                    border: 1px dashed #2DC079;
                    border-radius: 4px;

                    .inner-content {
                        padding: 29px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 30px;
                            color: #2DC079;
                        }

                        .inner-text {
                            font-size: 16px;
                            color: #666666;
                            margin-top: 20px;
                        }
                    }
                }

                .cover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 375px;
                    height: 160px;
                    margin-left: 15px;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .cover-bottom {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 32px;
                        background: #000000;
                        opacity: 0.5;
                        border-radius: 2px;
                        color: #ffffff;
                        font-size: 16px;
                        line-height: 36px;
                    }
                }
            }
        }

        .item-txt-tip {
            color: #999999;
        }
    }
</style>